import {HoobiizCartItemItemFull} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  CurrencyAmount,
  HoobiizCartItemType,
  HoobiizDiscountPercent,
  HoobiizDiscountType,
  HoobiizOrderItemItem,
} from '@shared/dynamo_model';
import {
  addCurrencyAmount,
  cents,
  multiplyCurrencyAmount,
  roundCents,
  substractCurrencyAmount,
} from '@shared/lib/hoobiiz/currency_amount';
import {applyDiscount} from '@shared/lib/hoobiiz/offer';
import {neverHappens} from '@shared/lib/type_utils';

export interface DiscountedCurrencyAmount {
  price1: CurrencyAmount;
  price2: CurrencyAmount;
  price3: CurrencyAmount;
}

export function baseDiscountedCurrencyAmount(price: CurrencyAmount): DiscountedCurrencyAmount {
  return {price1: price, price2: price, price3: price};
}

export function addDiscountedCurrencyAmount(
  amount1: DiscountedCurrencyAmount,
  amount2: DiscountedCurrencyAmount
): DiscountedCurrencyAmount {
  return {
    price1: addCurrencyAmount(amount1.price1, amount2.price1),
    price2: addCurrencyAmount(amount1.price2, amount2.price2),
    price3: addCurrencyAmount(amount1.price3, amount2.price3),
  };
}

export function increaseDiscountedCurrencyAmount(
  amount1: DiscountedCurrencyAmount,
  inc: CurrencyAmount
): DiscountedCurrencyAmount {
  return {
    price1: addCurrencyAmount(amount1.price1, inc),
    price2: addCurrencyAmount(amount1.price2, inc),
    price3: addCurrencyAmount(amount1.price3, inc),
  };
}

export function decreaseDiscountedCurrencyAmount(
  amount1: DiscountedCurrencyAmount,
  inc: CurrencyAmount
): DiscountedCurrencyAmount {
  return {
    price1: substractCurrencyAmount(amount1.price1, inc),
    price2: substractCurrencyAmount(amount1.price2, inc),
    price3: substractCurrencyAmount(amount1.price3, inc),
  };
}

export function multiplyDiscountedCurrencyAmount(
  multiplier: number,
  amount: DiscountedCurrencyAmount
): DiscountedCurrencyAmount {
  return {
    price1: multiplyCurrencyAmount(multiplier, amount.price1),
    price2: multiplyCurrencyAmount(multiplier, amount.price2),
    price3: multiplyCurrencyAmount(multiplier, amount.price3),
  };
}

export function flattenDiscountedCurrencyAmount(
  amount: DiscountedCurrencyAmount
): CurrencyAmount[] {
  const {price1, price2, price3} = amount;
  let priceSeq = [price1];
  if (roundCents(price2.cents) < roundCents(price1.cents)) {
    priceSeq.push(price2);
  } else if (roundCents(price2.cents) > roundCents(price1.cents)) {
    priceSeq = [price2];
  }
  if (roundCents(price3.cents) < roundCents(priceSeq.at(-1)?.cents ?? 0)) {
    priceSeq.push(price3);
  }
  return priceSeq;
}

export function discountedCurrencyAmountToPercent(
  amount: DiscountedCurrencyAmount
): HoobiizDiscountPercent {
  const {price1, price3} = amount;
  return {
    type: HoobiizDiscountType.Percent,
    percent:
      (100 * (roundCents(price1.cents) - roundCents(price3.cents))) / roundCents(price1.cents),
  };
}

type HoobiizOrderItemOption = Omit<HoobiizOrderItemItem['options'][0], 'quantity'>;
export function computeCartItemPrice(fullCartItem: HoobiizCartItemItemFull): {
  total: DiscountedCurrencyAmount;
  totalWithoutFees: DiscountedCurrencyAmount;
  ticketInfoTotal: DiscountedCurrencyAmount;
  options: HoobiizOrderItemOption[];
  fees: CurrencyAmount;
} {
  let total = baseDiscountedCurrencyAmount(cents(0));

  if (fullCartItem.type === 'ticket') {
    const {cartItem, offer, ticketInfo} = fullCartItem;
    const {id, youpiizPrice, publicPrice, fees = cents(0), options} = ticketInfo;

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (cartItem.itemType !== HoobiizCartItemType.Ticket) {
      return {total, totalWithoutFees: total, ticketInfoTotal: total, options: [], fees};
    }

    // Base ticket price
    const ticketInfoTotal = {
      price1: multiplyCurrencyAmount(cartItem.itemData.quantity, publicPrice),
      price2: multiplyCurrencyAmount(cartItem.itemData.quantity, youpiizPrice),
      price3: multiplyCurrencyAmount(
        cartItem.itemData.quantity,
        offer ? applyDiscount(offer.discount, youpiizPrice) : youpiizPrice
      ),
    };
    total = addDiscountedCurrencyAmount(total, ticketInfoTotal);

    // Add the options prices
    const optionsPrices: HoobiizOrderItemOption[] = [];
    for (const {optionId, quantity} of cartItem.itemData.options) {
      const option = options.find(o => o.id === optionId);
      if (!option) {
        throw new Error(`Option ${optionId} not found on ticket ${id}`);
      }
      const optionTotal = {
        price1: multiplyCurrencyAmount(quantity, option.publicPrice),
        price2: multiplyCurrencyAmount(quantity, option.youpiizPrice),
        price3: multiplyCurrencyAmount(quantity, option.youpiizPrice),
      };
      optionsPrices.push({
        option: {id: option.id, label: option.label, description: option.description},
        total: optionTotal,
      });
      total = addDiscountedCurrencyAmount(total, optionTotal);
    }

    // Add the fees
    const totalWithoutFees = total;
    const feesTotal = multiplyCurrencyAmount(cartItem.itemData.quantity, fees);
    total = increaseDiscountedCurrencyAmount(total, feesTotal);

    return {
      total,
      totalWithoutFees,
      ticketInfoTotal,
      options: optionsPrices,
      fees: feesTotal,
    };
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  } else if (fullCartItem.type === 'expert-ticket') {
    const {quantity} = fullCartItem.cartItem.itemData;
    const total = {
      price1: multiplyCurrencyAmount(quantity, fullCartItem.originalPrice),
      price2: multiplyCurrencyAmount(quantity, fullCartItem.price),
      price3: multiplyCurrencyAmount(quantity, fullCartItem.price),
    };
    return {total, totalWithoutFees: total, ticketInfoTotal: total, options: [], fees: cents(0)};
  }
  neverHappens(fullCartItem, `Unexpected cart item type`);
}
