import {FC, Fragment, useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCat1Id, HoobiizCat2Id, HoobiizCat3Id, HoobiizVendorId} from '@shared/dynamo_model';
import {SanitizedItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {ButtonAsLink} from '@shared-frontend/components/core/button';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizActivityGrid} from '@src/components/ui/hoobiiz_activity_grid';
import {useActivitySorting} from '@src/lib/stores';

interface HoobiizActivityModuleProps {
  catId?: HoobiizCat1Id | HoobiizCat2Id | HoobiizCat3Id;
  vendorId?: HoobiizVendorId;
  cse:
    | {
        mode: 'all';
        showCheckbox: boolean;
      }
    | {mode: 'cse-only' | 'non-cse-only'};
  title?: string;
}

const INITIAL_MAX_ACTIVITY_SHOWN = 15;
const ALL_ACTIVITY_SHOWN = 1e10;

export const HoobiizActivityModule: FC<HoobiizActivityModuleProps> = props => {
  const {catId, vendorId, cse, title} = props;
  const [cseCheckbox, setCseCheckbox] = useState(
    cse.mode === 'all' && cse.showCheckbox ? true : undefined
  );
  const [activityRes, setActivityRes] = useState<
    {activities: SanitizedItem<'HoobiizActivity'>[]; total: number} | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [maxActivityShown, setMaxActivityShown] = useState(INITIAL_MAX_ACTIVITY_SHOWN);
  const sort = useActivitySorting();

  useEffect(() => {
    const excludeCse = cse.mode === 'non-cse-only' || cseCheckbox === false;
    const excludeNonCse = cse.mode === 'cse-only';
    setIsLoading(true);
    apiCall(HoobiizApi, '/list-activity', {
      catId,
      vendorId,
      excludeCse,
      excludeNonCse,
      sort,
    })
      .then(res => {
        setActivityRes(res);
      })
      .catch(err =>
        notifyError(err, {message: `Une erreur s'est lors du chargement des activités`})
      )
      .finally(() => setIsLoading(false));
  }, [catId, cse.mode, cseCheckbox, sort, vendorId]);

  const handleShowMoreClick = useCallback(() => {
    setMaxActivityShown(ALL_ACTIVITY_SHOWN);
  }, []);

  // if () {
  //   return <Wrapper>Loading...</Wrapper>;
  // }

  const plural = activityRes?.total === 1 ? '' : 's';

  const titleEl = title === undefined ? EmptyFragment : <HeaderTitle>{title}</HeaderTitle>;
  const resultCountEl = (
    <ResultFound>
      {isLoading || !activityRes ? (
        'Chargement...'
      ) : (
        <Fragment>
          <ResultFoundCount>{activityRes.total}</ResultFoundCount>
          {`résultat${plural} trouvé${plural}`}
        </Fragment>
      )}
    </ResultFound>
  );
  const cseCheckboxEl =
    cseCheckbox !== undefined ? (
      <Checkbox checked={cseCheckbox} syncState={setCseCheckbox}>
        <CseCheckboxLabel>Afficher les offres CSE</CseCheckboxLabel>
      </Checkbox>
    ) : (
      EmptyFragment
    );

  return (
    <Wrapper>
      <Header $withTitle={title !== undefined}>
        {cseCheckbox === undefined ? (
          <Fragment>
            <HeaderLeft>{titleEl}</HeaderLeft>
            {resultCountEl}
          </Fragment>
        ) : (
          <Fragment>
            <HeaderLeft>
              {titleEl}
              {resultCountEl}
            </HeaderLeft>
            {cseCheckboxEl}
          </Fragment>
        )}
      </Header>
      <HoobiizActivityGrid activities={activityRes?.activities.slice(0, maxActivityShown)} />
      {maxActivityShown === ALL_ACTIVITY_SHOWN ||
      !activityRes ||
      activityRes.activities.length <= maxActivityShown ? (
        EmptyFragment
      ) : (
        <ShowMoreWrapper>
          <ButtonAsLink onClick={handleShowMoreClick}>Afficher plus</ButtonAsLink>
        </ShowMoreWrapper>
      )}
    </Wrapper>
  );
};

HoobiizActivityModule.displayName = 'HoobiizActivityModule';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const Header = styled.div<{$withTitle: boolean}>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  ${p =>
    p.$withTitle
      ? `
  border-bottom: solid 2px ${Colors.Gold};
  padding-bottom: 8px;
  `
      : false}
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;

const HeaderTitle = styled.div`
  font-size: 22px;
  font-weight: 700;
`;

const CseCheckboxLabel = styled.div`
  font-weight: 600;
  font-style: italic;
  color: ${Colors.Grey};
`;

const ResultFound = styled.div`
  display: flex;
  gap: 4px;
  color: ${Colors.Grey};
`;

const ResultFoundCount = styled.div`
  font-weight: 700;
  color: ${Colors.DarkGrey};
`;

const ShowMoreWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
