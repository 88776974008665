import {ChangeEventHandler, FC, Fragment, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizActivityId,
  HoobiizStockId,
  HoobiizStockReservation,
  HoobiizStockReservationType,
  HoobiizTicketInfoId,
  HoobiizTicketInfoOptionId,
} from '@shared/dynamo_model';
import {IS_PRODUCTION_ENV} from '@shared/env_constants';
import {
  addDiscountedCurrencyAmount,
  DiscountedCurrencyAmount,
  multiplyDiscountedCurrencyAmount,
} from '@shared/lib/hoobiiz/discounted_currency_amount';
import {findBestOffer} from '@shared/lib/hoobiiz/offer';
import {NonEmptyArray, removeUndefined} from '@shared/lib/type_utils';
import {FullItem, SanitizedItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Button, ButtonAsLink} from '@shared-frontend/components/core/button';
import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {showRawModal} from '@shared-frontend/components/core/modal';
import {Radio} from '@shared-frontend/components/core/radio';
import {localTime} from '@shared-frontend/lib/date_format';
import {triggerUrlFileDownload} from '@shared-frontend/lib/download';
import {EmptyFragment, InputHandler} from '@shared-frontend/lib/react';
import {useOptionalStringQueryString} from '@shared-frontend/lib/use_query_string';

import {AdminActivityView} from '@src/components/admin/activity/admin_activity_view';
import {HoobiizAdminStockCalendarModal} from '@src/components/admin/activity_stock/hoobiiz_admin_stock_calendar_modal';
import {HoobiizAdminStockCalendarPdfDebugModal} from '@src/components/admin/activity_stock/hoobiiz_admin_stock_calendar_pdf_debug_modal';
import {AdminVendorView} from '@src/components/admin/vendor/admin_vendor_view';
import {HoobiizPrices} from '@src/components/ui/hoobiiz_prices';
import {HoobiizTicketPrices} from '@src/components/ui/hoobiiz_ticket_prices';
import {NumberPicker} from '@src/components/ui/number_picker';
import {getDiscount} from '@src/lib/discount';

interface HoobiizAdminStockCalendarPdfModalProps {
  activityId: HoobiizActivityId;
  stocks: NonEmptyArray<{
    stock: FullItem<'HoobiizStock'>;
    offers: SanitizedItem<'HoobiizOffer'>[];
  }>;
  nextStocks: NonEmptyArray<{
    stock: FullItem<'HoobiizStock'>;
    offers: SanitizedItem<'HoobiizOffer'>[];
  }>[];
}

export const HoobiizAdminStockCalendarPdfModal: FC<
  HoobiizAdminStockCalendarPdfModalProps
> = props => {
  const {activityId, stocks, nextStocks} = props;
  const setDateStr = useOptionalStringQueryString('date')[1];

  const {stock, offers} = stocks[0];
  const {ticketInfo, activity, reservation} = stock;
  const ticketAndStocks = useMemo(() => {
    return removeUndefined(
      ticketInfo.map(ti => {
        const {span = 1} = ti;
        const stocksSpan = [stocks, ...nextStocks.slice(0, span - 1)].map(stockGroup => {
          const best = stockGroup
            .map(stock => ({
              remaining: stock.stock.remaining,
              id: stock.stock.id,
              reservation: stock.stock.reservation,
            }))
            .filter(s => s.remaining > 0)
            .sort((a1, a2) => a2.remaining - a1.remaining)[0];
          return best;
        });
        let minAvailable = Number.POSITIVE_INFINITY;
        const stockIds: {id: HoobiizStockId; reservation: HoobiizStockReservation}[] = [];
        for (const s of stocksSpan) {
          if (!s) {
            return undefined;
          }
          stockIds.push({id: s.id, reservation: s.reservation});
          minAvailable = Math.min(minAvailable, s.remaining);
        }
        if (stocksSpan.length < span) {
          return undefined;
        }
        return {
          ticket: ti,
          offers,
          stockIds,
          maxQuantity: minAvailable,
        };
      })
    );
  }, [ticketInfo, stocks, nextStocks, offers]);
  const [selectedTicket, setSelectedTicket] = useState(ticketAndStocks[0]?.ticket.id);
  const [selectedOptions, setSelectedOptions] = useState<HoobiizTicketInfoOptionId[]>([]);
  const [quantity, setQuantity] = useState(1);
  const selectedTicketAndStocks = useMemo(
    () => ticketAndStocks.find(t => t.ticket.id === selectedTicket),
    [ticketAndStocks, selectedTicket]
  );

  const handleTicketInfoChange = useCallback<InputHandler>(evt => {
    setSelectedTicket(evt.target.value as HoobiizTicketInfoId);
    setSelectedOptions([]);
  }, []);

  const handleTicketInfoOptionChange = useCallback<ChangeEventHandler>(evt => {
    const idStr = evt.currentTarget.getAttribute('data-option-id');
    // eslint-disable-next-line no-null/no-null
    if (idStr === null) {
      return;
    }
    const id = idStr as HoobiizTicketInfoOptionId;
    setSelectedOptions(curr =>
      curr.includes(id) ? curr.filter(val => val !== id) : [...curr, id]
    );
  }, []);

  const handleGenerateClick = useCallback(async () => {
    if (selectedTicketAndStocks === undefined) {
      return;
    }
    const {ticket, stockIds, offers} = selectedTicketAndStocks;
    const {url} = await apiCall(HoobiizApi, '/admin/generate-pdf', {
      options: selectedOptions.map(optionId => ({optionId, quantity})),
      quantity,
      stocks: stockIds,
      ticketInfo: ticket,
      offerId: findBestOffer(ticket, offers)?.id,
      activityId,
    });
    triggerUrlFileDownload({url});
  }, [activityId, quantity, selectedOptions, selectedTicketAndStocks]);

  const handleDebugClick = useCallback(() => {
    if (selectedTicketAndStocks === undefined) {
      return;
    }
    const {ticket, stockIds, offers} = selectedTicketAndStocks;
    showRawModal({
      mode: 'fade-center',
      children: (
        <HoobiizAdminStockCalendarPdfDebugModal
          req={{
            options: selectedOptions.map(optionId => ({optionId, quantity})),
            quantity,
            stocks: stockIds,
            ticketInfo: ticket,
            offerId: findBestOffer(ticket, offers)?.id,
            activityId,
          }}
        />
      ),
      onHide: () => {
        setDateStr(undefined);
      },
    });
  }, [activityId, quantity, selectedOptions, selectedTicketAndStocks, setDateStr]);

  let title = <div>Date Flexible</div>;
  if (reservation.type === HoobiizStockReservationType.Fixed) {
    const dayStr = new Date(reservation.period.startTs)
      .toLocaleDateString('fr', {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      .toUpperCase();
    const hourStr = `${localTime(reservation.period.startTs)} à ${localTime(
      reservation.period.endTs
    )}`;
    title = (
      <Fragment>
        <div>{dayStr}</div>
        <div>{`Créneau ${hourStr}`}</div>
      </Fragment>
    );
  }

  const handleBackClick = useCallback(() => {
    showRawModal({
      mode: 'slide-down',
      children: <HoobiizAdminStockCalendarModal activityId={activityId} />,
      onHide: () => {
        setDateStr(undefined);
      },
      noAutoHide: true,
    });
  }, [activityId, setDateStr]);

  let total: DiscountedCurrencyAmount | undefined;
  if (selectedTicketAndStocks) {
    const {amount} = getDiscount(selectedTicketAndStocks.ticket, offers);
    total = multiplyDiscountedCurrencyAmount(quantity, amount);
    const {options = []} = selectedTicketAndStocks.ticket;
    for (const optionId of selectedOptions) {
      const option = options.find(o => o.id === optionId);
      if (!option) {
        continue;
      }
      total = addDiscountedCurrencyAmount(
        total,
        multiplyDiscountedCurrencyAmount(quantity, {
          price1: option.publicPrice,
          price2: option.youpiizPrice,
          price3: option.youpiizPrice,
        })
      );
    }
  }

  return (
    <Wrapper>
      <BackButton onClick={handleBackClick}>Retour</BackButton>
      <Title>{title}</Title>

      <div>
        <SectionTitle>ACTIVITÉ</SectionTitle>
        <AdminActivityView activity={activity} />
      </div>

      <div>
        <SectionTitle>PARTENAIRE</SectionTitle>
        <AdminVendorView vendor={activity?.vendor} />
      </div>

      {ticketAndStocks.length === 0 ? (
        <div>Stock épuisé</div>
      ) : (
        <div>
          <SectionTitle>TICKETS</SectionTitle>
          {ticketAndStocks.map(({ticket, offers}) => (
            <div key={ticket.id}>
              <Radio
                name={'ticket-info'}
                value={ticket.id}
                checked={selectedTicket === ticket.id}
                onChange={handleTicketInfoChange}
                overrides={{alignItems: 'baseline'}}
              >
                <Item>
                  <div>{ticket.label}</div>
                  <HoobiizTicketPrices ticketInfo={ticket} offers={offers} />
                </Item>
              </Radio>
              {selectedTicket === ticket.id ? (
                <CheckboxWrapper>
                  {(ticket.options ?? []).map(o => (
                    <Checkbox
                      data-option-id={o.id}
                      key={o.id}
                      value={o.id}
                      checked={selectedOptions.includes(o.id)}
                      onChange={handleTicketInfoOptionChange}
                    >
                      <Item>
                        <div>{o.label}</div>
                        <HoobiizTicketPrices ticketInfo={o} />
                      </Item>
                    </Checkbox>
                  ))}
                </CheckboxWrapper>
              ) : (
                EmptyFragment
              )}
            </div>
          ))}
        </div>
      )}

      {selectedTicketAndStocks ? (
        <div>
          <SectionTitle>QUANTITÉ</SectionTitle>
          <NumberPicker
            value={quantity}
            onChange={setQuantity}
            min={1}
            max={selectedTicketAndStocks.maxQuantity}
          />
        </div>
      ) : (
        EmptyFragment
      )}

      {total ? (
        <div>
          <SectionTitle>TOTAL</SectionTitle>
          <HoobiizPrices prices={total} />
        </div>
      ) : (
        EmptyFragment
      )}
      <Buttons>
        <Button onClickAsync={handleGenerateClick} disabled={!selectedTicket}>
          Générer PDF
        </Button>
        {IS_PRODUCTION_ENV ? EmptyFragment : <Button onClick={handleDebugClick}>Debug PDF</Button>}
      </Buttons>
    </Wrapper>
  );
};

HoobiizAdminStockCalendarPdfModal.displayName = 'HoobiizAdminStockCalendarPdfModal';

const Wrapper = styled.div`
  position: relative;
  padding: 45px 32px 32px 32px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-width: 400px;
  gap: 32px;
`;

const BackButton = styled(ButtonAsLink)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const CheckboxWrapper = styled.div`
  margin-left: 24px;
`;

const Title = styled.div`
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #333;
`;

const SectionTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
  color: #a8945f;
`;

const Item = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

const Buttons = styled.div`
  display: flex;
  gap: 16px;
`;
