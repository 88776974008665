import {InfoWindow, Marker} from '@react-google-maps/api';
import {FC, Fragment} from 'react';
import styled from 'styled-components';

import {PlaceLatLngLiteral} from '@shared/api/definitions/google_places_api';

import {EmptyFragment} from '@shared-frontend/lib/react';

interface HoobiizGoogleMapsTooltipProps {
  position: PlaceLatLngLiteral;
  title?: string;
  line1?: string;
  line2?: string;
}

export const HoobiizGoogleMapsTooltip: FC<HoobiizGoogleMapsTooltipProps> = props => {
  const {title, line1, line2, position} = props;
  return (
    <Fragment>
      <Marker position={position}></Marker>
      <InfoWindow position={position}>
        <MarkerWindow>
          {title === undefined ? EmptyFragment : <MarkerWindowTitle>{title}</MarkerWindowTitle>}
          {line1 === undefined ? EmptyFragment : <MarkerWindowText>{line1}</MarkerWindowText>}
          {line2 === undefined ? EmptyFragment : <MarkerWindowText>{line2}</MarkerWindowText>}
        </MarkerWindow>
      </InfoWindow>
    </Fragment>
  );
};
HoobiizGoogleMapsTooltip.displayName = 'HoobiizGoogleMapsTooltip';

const MarkerWindow = styled.div`
  width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  border-radius: 6px;
  background-color: #fffffff0;
  padding: 14px;
  font-weight: 500;
`;

const MarkerWindowText = styled.div`
  font-weight: 500;
  font-size: 15px;
`;

const MarkerWindowTitle = styled.div`
  font-weight: 700;
  font-size: 15px;
`;
