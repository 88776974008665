import {FC, useCallback, useState} from 'react';
import styled from 'styled-components';

import {HoobiizData} from '@shared/api/definitions/public_api/hoobiiz_api';
import {
  HoobiizMediaId,
  HoobiizVendorItem,
  HoobiizVendorLocation,
  HoobiizVisibility,
  Markdown,
} from '@shared/dynamo_model';
import {FullItem} from '@shared/model/search_tables';

import {Button, ButtonAsLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Textarea} from '@shared-frontend/components/core/textarea_v2';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {ExternalUrlLink} from '@src/components/admin/form/external_url_link';
import {
  FormBlock,
  FormBlockFull,
  FormFlex,
  FormLabel,
} from '@src/components/admin/form/form_fragments';
import {FormSection} from '@src/components/admin/form/form_section';
import {HoobiizVisibilityForm} from '@src/components/admin/form/hoobiiz_visibility_form';
import {MarkdownHelpLabel} from '@src/components/admin/form/markdown_help_label';
import {MediaUpload} from '@src/components/admin/form/media_upload';
import {MediaUploadStatus} from '@src/components/admin/form/media_upload_status';
import {VendorContactForm} from '@src/components/admin/vendor/vendor_contact_form';
import {VendorLocationForm} from '@src/components/admin/vendor/vendor_location_form';
import {adminInputTheme, AlertButton} from '@src/components/core/theme';
import {Sizes} from '@src/components/core/theme_base';
import {HoobiizVendorPageView} from '@src/components/ui/hoobiiz_vendor_page_view';
import {toSlugName} from '@src/lib/hoobiiz_urls';

interface VendorFormProps {
  initialData?: Partial<FullItem<'HoobiizVendor'>>;
  submitButtonText?: string;
  onSubmit?: (data: HoobiizData<HoobiizVendorItem>) => Promise<void>;
  onDelete?: () => Promise<void>;
}

export const VendorForm: FC<VendorFormProps> = props => {
  const {initialData, submitButtonText, onSubmit, onDelete} = props;

  const [name, setName] = useState(initialData?.name ?? '');
  const [slugName, setSlugName] = useState(
    initialData?.slugName ?? toSlugName(initialData?.name ?? '')
  );
  const setNameAndSlugName = useCallback((str: string) => {
    setName(prevName => {
      // Only update slug name and address name if it was not manually set
      setSlugName(prevSlugName => {
        return prevSlugName === toSlugName(prevName) ? toSlugName(str) : prevSlugName;
      });
      setAddress(prevAddress => {
        return prevAddress === undefined || prevAddress.name === prevName
          ? {...prevAddress, name: str}
          : prevAddress;
      });
      return str;
    });
  }, []);
  const [comments, setComments] = useState(initialData?.comments ?? '');

  const [address, setAddress] = useState<Partial<HoobiizVendorLocation | undefined>>(
    initialData?.addresses?.[0]
  );
  const [contact, setContact] = useState(
    initialData?.contact ?? {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    }
  );
  const [markdown, setMarkdown] = useState(initialData?.markdown ?? ('' as Markdown));
  const mediaWithIds = initialData?.mediaIds?.map(id => ({
    id,
    media: initialData.media?.find(m => m.id === id),
  }));
  const [media, setMedia] = useState(mediaWithIds);
  const logoWithId = {
    id: initialData?.logoMediaId,
    media: initialData?.logo,
  };
  const [logo, setLogo] = useState(logoWithId);
  const [visibility, setVisibility] = useState(initialData?.visibility ?? HoobiizVisibility.Public);

  const [previewHidden, setPreviewHidden] = useState(false);
  const handlePreviewClick = useCallback(() => setPreviewHidden(hidden => !hidden), []);

  const handleCreateClick = useCallback(async () => {
    if (!address) {
      notifyError('Adresse manquante');
      return;
    }
    const {address: addressData, name, openingHours, phone, ...rest} = address;
    if (addressData === undefined) {
      notifyError('Adresse manquante');
      return;
    }
    if (name === undefined) {
      notifyError('Nom manquant');
      return;
    }
    if (openingHours === undefined) {
      notifyError('Horaires manquantes');
      return;
    }
    if (phone === undefined) {
      notifyError('Numéro de téléphone manquant');
      return;
    }

    return onSubmit?.({
      name,
      slugName,
      addresses: [{address: addressData, name, openingHours, phone, ...rest}],
      contact,
      markdown,
      visibility,
      mediaIds: media?.map(media => media.id) ?? [],
      logoMediaId: logo.id,
      comments,
    });
  }, [address, comments, contact, logo.id, markdown, media, onSubmit, slugName, visibility]);

  const handleMediaChange = useCallback((statuses: MediaUploadStatus[]) => {
    const media = [] as {media: FullItem<'HoobiizMedia'> | undefined; id: HoobiizMediaId}[];
    for (const status of statuses) {
      if (status.status === 'success') {
        media.push({media: status.media, id: status.id});
      }
    }
    setMedia(media);
  }, []);

  const handleLogoChange = useCallback((statuses: MediaUploadStatus[]) => {
    const [status] = statuses;
    if (!status) {
      setLogo({id: undefined, media: undefined});
      return;
    }
    if (status.status === 'success') {
      setLogo({media: status.media, id: status.id});
    }
  }, []);

  return (
    <Wrapper>
      <FormWrapper>
        {/* INFO */}
        <FormSection title="INFO">
          <FormFlex>
            <FormBlock>
              <Input
                width="100%"
                value={name}
                syncState={setNameAndSlugName}
                placeholder={initialData?.name}
                label="NOM"
                autoFocus
                overrides={adminInputTheme}
              />
            </FormBlock>
            <FormBlock>
              <Input
                width="100%"
                value={slugName}
                syncState={setSlugName}
                placeholder={initialData?.slugName}
                label="URL NAME"
                overrides={adminInputTheme}
              />
            </FormBlock>
            <FormBlock>
              <HoobiizVisibilityForm value={visibility} syncState={setVisibility} />
            </FormBlock>
          </FormFlex>
          <FormBlockFull>
            <FormLabel>LOGO</FormLabel>
            <MediaUpload
              initialMedia={
                logoWithId.id === undefined ? [] : [{id: logoWithId.id, media: logoWithId.media}]
              }
              onChange={handleLogoChange}
            />
          </FormBlockFull>
          <FormBlockFull>
            <Textarea
              width="100%"
              value={comments}
              syncState={setComments}
              placeholder={initialData?.comments}
              label="BLOC NOTES (non visible sur le site)"
              overrides={adminInputTheme}
              height={200}
            />
          </FormBlockFull>
          {initialData?.externalUrl === undefined ? (
            EmptyFragment
          ) : (
            <ExternalUrlLink url={initialData.externalUrl} />
          )}
        </FormSection>

        {/* ÉTABLISSEMENT */}
        <FormSection title="ÉTABLISSEMENT">
          <VendorLocationForm location={address} onChange={setAddress} />
        </FormSection>

        {/* INTERLOCUTEUR */}
        <FormSection title="INTERLOCUTEUR">
          <VendorContactForm initialData={contact} onChange={setContact} />
        </FormSection>

        {/* PAGE */}
        <FormSection title="PAGE">
          <FormFlex>
            <FormBlockFull>
              <Textarea
                width="100%"
                height={400}
                value={markdown}
                syncState={setMarkdown}
                placeholder={initialData?.markdown}
                label={<MarkdownHelpLabel title="CONTENU" />}
                overrides={adminInputTheme}
              />
            </FormBlockFull>
            <FormBlockFull>
              <FormLabel>IMAGES</FormLabel>
              <MediaUpload initialMedia={mediaWithIds} multiple onChange={handleMediaChange} />
            </FormBlockFull>
          </FormFlex>
        </FormSection>

        {/* BUTTONS */}
        <ButtonsWrapper>
          <Button onClickAsync={handleCreateClick} disabled={address === undefined}>
            {submitButtonText}
          </Button>
          {onDelete ? <AlertButton onClickAsync={onDelete}>Supprimer</AlertButton> : EmptyFragment}
        </ButtonsWrapper>
      </FormWrapper>
      <PreviewSection
        title="APERÇUS"
        titleComponent={
          <ButtonAsLink onClick={handlePreviewClick}>
            {previewHidden ? 'Agrandir' : 'Réduire'}
          </ButtonAsLink>
        }
        noMargin
        $hidden={previewHidden}
      >
        <StyledHoobiizVendorPageView
          vendorId={initialData?.id}
          name={name}
          media={media}
          info={markdown}
          location={address}
        />
      </PreviewSection>
    </Wrapper>
  );
};
VendorForm.displayName = 'VendorForm';

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PreviewSection = styled(FormSection)<{$hidden: boolean}>`
  position: sticky;
  top: 32px;
  max-height: calc(100vh - 64px);
  overflow-y: scroll;
  flex-shrink: 0;
  ${p => p.$hidden && `margin-right: -450px;`}
  transition: margin-right ease-in-out 300ms;
`;

const StyledHoobiizVendorPageView = styled(HoobiizVendorPageView)`
  zoom: 0.5;
  width: ${Sizes.Content};
  margin-top: 69px;
`;
