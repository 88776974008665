import {Dispatch, FC, useCallback, useMemo} from 'react';
import styled from 'styled-components';

import {HoobiizApi} from '@shared/api/definitions/public_api/hoobiiz_api';
import {FilterOp, SearchApiQueryType} from '@shared/api/definitions/search_api';
import {HoobiizCat1Id, HoobiizCat2Id} from '@shared/dynamo_model';
import {useSsrContext} from '@shared/frontends/use_ssr_context';
import {FullItem} from '@shared/model/search_tables';

import {apiCall} from '@shared-frontend/api';
import {Autocomplete} from '@shared-frontend/components/core/autocomplete';
import {NavLink} from '@shared-frontend/components/core/button';
import {Image} from '@shared-frontend/components/core/image';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {adminInputTheme} from '@src/components/core/theme';
import {mediaSrcData} from '@src/lib/hoobiiz_media';

interface Category3InputProps {
  category3?: FullItem<'HoobiizCat3'>;
  label?: string;
  syncState?: Dispatch<FullItem<'HoobiizCat3'> | undefined>;
  autoFocus?: boolean;
  cat1Filter?: HoobiizCat1Id;
  cat2Filter?: HoobiizCat2Id;
}

export const Category3Input: FC<Category3InputProps> = props => {
  const {
    category3,
    label: labelStr = 'CATÉGORIE PARENTE',
    syncState,
    autoFocus,
    cat1Filter,
    cat2Filter,
  } = props;
  const {host} = useSsrContext();

  const filter = useMemo(
    () =>
      cat2Filter === undefined
        ? cat1Filter === undefined
          ? undefined
          : ({cat1Id: {type: FilterOp.Equal, value: cat1Filter}} as const)
        : ({cat2Id: {type: FilterOp.Equal, value: cat2Filter}} as const),
    [cat1Filter, cat2Filter]
  );

  const category3Lookup = useCallback(
    async (inputText: string) =>
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizCat3',
        match: {matcher: 'name', value: inputText},
        filter,
        limit: 10,
        mode: 'full',
      }).then(res => {
        const {items} = res as SearchApiQueryType<'HoobiizCat3', 'full'>['res'];
        return items;
      }),
    [filter]
  );

  const lookupInitial = useCallback(
    async () =>
      apiCall(HoobiizApi, '/admin/search/query', {
        table: 'HoobiizCat3',
        filter,
        limit: 100,
        mode: 'full',
      }).then(res => {
        const {items} = res as SearchApiQueryType<'HoobiizCat3', 'full'>['res'];
        return items;
      }),
    [filter]
  );

  const resultToString = useCallback((res: FullItem<'HoobiizCat3'>) => res.name, []);
  const resultToKey = useCallback((res: FullItem<'HoobiizCat3'>) => res.id, []);
  const resultToElement = useCallback(
    (res: FullItem<'HoobiizCat3'>, highlighted: boolean) => {
      const {media, mediaId} = res;
      return (
        <Result $highlighted={highlighted}>
          {media ? (
            <StyledImage
              alt={''}
              srcAndSizes={{srcData: mediaSrcData(host, {media, id: mediaId}), width: 48}}
              rawUrls
              width={48}
              height={48}
            />
          ) : (
            EmptyFragment
          )}
          <ResultVendor>
            <ResultMainText>{res.name}</ResultMainText>
          </ResultVendor>
        </Result>
      );
    },
    [host]
  );

  const label =
    category3 === undefined ? (
      labelStr
    ) : (
      <LabelWithLink>
        {labelStr}
        <NavLink
          to={`/admin/cat1/${category3.cat2?.cat1Id}/cat2/${category3.cat2Id}/cat3/${category3.id}`}
        >
          Voir
        </NavLink>
      </LabelWithLink>
    );

  return (
    <Autocomplete
      lookupItem={category3Lookup}
      lookupInitial={lookupInitial}
      item={category3}
      syncState={syncState}
      itemToInputString={resultToString}
      itemToKey={resultToKey}
      label={label}
      overrides={adminInputTheme}
      itemElement={resultToElement}
      autoFocus={autoFocus}
    />
  );
};
Category3Input.displayName = 'Category3Input';

const Result = styled.div<{$highlighted?: boolean}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 14px;
  padding: 14px;
  cursor: pointer;
  ${p => (p.$highlighted ? `background-color: #f6f6f6;` : `background-color: #ffffff;`)};
  &:hover {
    background-color: #f6f6f6;
  }
`;

const ResultVendor = styled.div`
  display: flex;
  flex-direction: column;
`;

const ResultMainText = styled.span`
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
`;

const StyledImage = styled(Image)`
  border-radius: 8px;
  box-shadow: 0 0 7px -1px #0000002e;
`;

const LabelWithLink = styled.div`
  display: flex;
  gap: 8px;
`;
