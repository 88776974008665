import {FC} from 'react';
import styled from 'styled-components';

import {HoobiizActivityModule} from '@src/components/ui/hoobiiz_activity_module';
import {HoobiizCat1ListView} from '@src/components/ui/hoobiiz_cat1_list_view';

interface HomePageProps {}

export const HomePage: FC<HomePageProps> = () => {
  return (
    <Wrapper>
      <HoobiizCat1ListView />
      <HoobiizActivityModule cse={{mode: 'non-cse-only'}} title="Les nouvelles activités" />
      <HoobiizActivityModule
        cse={{mode: 'cse-only'}}
        title="Les dernières offres réservées aux CSE"
      />
    </Wrapper>
  );
};

HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 48px;
`;
