import {useCallback, useRef} from 'react';
import styled from 'styled-components';

import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {Custom, NULL_REF} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';

interface NumberPickerProps {
  min?: number;
  max?: number;
  value: number;
  onChange?: (newVal: number, el: HTMLDivElement) => void;
}

const BORDER_RADIUS = 4;
const BORDER_WIDTH = 2;
const HEIGHT = 32;
const ENABLED_COLOR = '#e0e1e2';
const DISABLED_COLOR = '#f4f4f4';

export const NumberPicker: Custom<NumberPickerProps, 'div', 'onChange'> = props => {
  const {min = 1, max = Number.POSITIVE_INFINITY, value, onChange, ...rest} = props;
  const ref = useRef<HTMLDivElement>(NULL_REF);

  const handleChange = useCallback(
    (val: number) => {
      if (!ref.current) {
        return;
      }
      onChange?.(val, ref.current);
    },
    [onChange]
  );

  const handleMinClick = useCallback(() => {
    const newVal = value - 1;
    handleChange(newVal > max ? max : Math.max(newVal, min));
  }, [handleChange, max, min, value]);

  const handleMaxClick = useCallback(() => {
    const newVal = value + 1;
    handleChange(newVal < min ? min : Math.min(newVal, max));
  }, [handleChange, max, min, value]);

  return (
    <Wrapper ref={ref} {...rest}>
      <MinMax $disabled={value <= min} $left onClick={handleMinClick}>
        <SvgIcon name="Minus" size={9} color={value <= min ? DISABLED_COLOR : ENABLED_COLOR} />
      </MinMax>
      <Val>{value}</Val>
      <MinMax $disabled={value >= max} $right onClick={handleMaxClick}>
        <SvgIcon name="Plus" size={9} color={value >= max ? DISABLED_COLOR : ENABLED_COLOR} />
      </MinMax>
    </Wrapper>
  );
};

NumberPicker.displayName = 'NumberPicker';

const Wrapper = styled.div`
  display: flex;
  background-color: #ffffff;
`;

const MinMax = styled.div<{$disabled: boolean; $left?: boolean; $right?: boolean}>`
  width: ${HEIGHT}px;
  height: ${HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  ${p =>
    p.$disabled
      ? `
    cursor: normal;
    border: solid ${BORDER_WIDTH}px ${DISABLED_COLOR};
    color: ${DISABLED_COLOR};
  `
      : `
    cursor: pointer;
    border: solid ${BORDER_WIDTH}px ${ENABLED_COLOR};
    color: ${ENABLED_COLOR};
  `}
  ${p =>
    p.$left &&
    `
    margin-right: -${BORDER_WIDTH}px;
    border-right-color: transparent;
    border-top-left-radius: ${BORDER_RADIUS}px;
    border-bottom-left-radius: ${BORDER_RADIUS}px;
  `}
  ${p =>
    p.$right &&
    `
    margin-left: -${BORDER_WIDTH}px;
    border-left-color: transparent;
    border-top-right-radius: ${BORDER_RADIUS}px;
    border-bottom-right-radius: ${BORDER_RADIUS}px;
  `}
  user-select: none;
`;
const Val = styled.div`
  width: 56px;
  height: ${HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.Grey};
  border: solid ${BORDER_WIDTH}px ${ENABLED_COLOR};
  z-index: 1;
`;
