import {ApiMetadata} from '@shared/api/model';
import {
  HoobiizActivityId,
  HoobiizExpertTicketInfoId,
  HoobiizExpertTicketProductId,
  HoobiizExpertTicketStockId,
} from '@shared/dynamo_model';
import {None} from '@shared/lib/type_utils';
import {
  FullItem,
  SanitizedItem,
  SearchTable,
  SearchTableFilter,
  SearchTableTypes,
} from '@shared/model/search_tables';
import {searchHost} from '@shared/terraform';

//
// SearchQuery
//

export interface Gsi<
  Table extends SearchTable,
  GsiName extends keyof SearchTableTypes[Table]['gsi'],
> {
  name: GsiName;
  where?: (
    | {
        type: 'equal';
        value: SearchTableTypes[Table]['gsi'][GsiName];
      }
    | {
        type: 'between';
        min: SearchTableTypes[Table]['gsi'][GsiName];
        max: SearchTableTypes[Table]['gsi'][GsiName];
      }
  ) & {rand?: boolean};
  desc?: boolean;
}
type GsiTypeRecord<Table extends SearchTable> = {
  [GsiName in keyof SearchTableTypes[Table]['gsi']]: Gsi<Table, GsiName>;
};
type GsiType<Table extends SearchTable> = GsiTypeRecord<Table>[keyof GsiTypeRecord<Table>];

interface Match<
  Table extends SearchTable,
  Matcher extends keyof SearchTableTypes[Table]['matchers'],
> {
  matcher: Matcher;
  value: SearchTableTypes[Table]['matchers'][Matcher];
}
type MatchTypeRecord<Table extends SearchTable> = {
  [Matcher in keyof SearchTableTypes[Table]['matchers']]: Match<Table, Matcher>;
};
type MatchType<Table extends SearchTable> = MatchTypeRecord<Table>[keyof MatchTypeRecord<Table>];

interface Sort<
  Table extends SearchTable,
  SortName extends keyof SearchTableTypes[Table]['sorters'],
> {
  name: SortName;
  params: SearchTableTypes[Table]['sorters'][SortName];
  desc?: boolean;
}
type SortTypeRecord<Table extends SearchTable> = {
  [SortName in keyof SearchTableTypes[Table]['sorters']]: Sort<Table, SortName>;
};
export type SortType<Table extends SearchTable> =
  SortTypeRecord<Table>[keyof SortTypeRecord<Table>];

export enum FilterOp {
  Equal = 'equal',
  Contain = 'contain',
}

export type FilterType<Table extends SearchTable> = {
  [Key in keyof SearchTableFilter<Table>]?:
    | {
        type: FilterOp.Equal;
        value: SearchTableFilter<Table>[Key] extends unknown[]
          ? never
          : SearchTableFilter<Table>[Key];
      }
    | {
        type: FilterOp.Contain;
        value: SearchTableFilter<Table>[Key] extends (infer T)[] ? T : never;
      };
};

export interface SearchApiQueryType<Table extends SearchTable, Mode extends 'full' | 'sanitized'> {
  req: {
    table: Table;
    gsi?: GsiType<Table>;
    match?: MatchType<Table>;
    sort?: SortType<Table>;
    filter?: FilterType<Table>;
    limit?: number;
    start?: number;
    mode: Mode;
  };
  res: {
    items: (Mode extends 'full' ? FullItem<Table> : SanitizedItem<Table>)[];
    total: number;
    took: number;
  };
}
type SearchApiQueryTypeRecord = {
  [Table in SearchTable]:
    | SearchApiQueryType<Table, 'full'>
    | SearchApiQueryType<Table, 'sanitized'>;
};
type SearchApiQueryEndpoint = SearchApiQueryTypeRecord[keyof SearchApiQueryTypeRecord];

//
// SearchGet
//

export interface SearchApiGetType<Table extends SearchTable, Mode extends 'full' | 'sanitized'> {
  req: {
    table: Table;
    id: SearchTableTypes[Table]['itemId'];
    mode: Mode;
  };
  res: {
    item?: Mode extends 'full' ? FullItem<Table> : SanitizedItem<Table>;
    took: number;
  };
}
type SearchApiGetTypeRecord = {
  [Table in SearchTable]: SearchApiGetType<Table, 'full'> | SearchApiGetType<Table, 'sanitized'>;
};
type SearchApiGetEndpoint = SearchApiGetTypeRecord[keyof SearchApiGetTypeRecord];

//
// SearchBatchGet
//

export interface SearchApiBatchGetType<
  Table extends SearchTable,
  Mode extends 'full' | 'sanitized',
> {
  req: {
    table: Table;
    ids: SearchTableTypes[Table]['itemId'][];
    mode: Mode;
  };
  res: {
    items: Record<
      SearchTableTypes[Table]['itemId'],
      (Mode extends 'full' ? FullItem<Table> : SanitizedItem<Table>) | undefined
    >;
    took: number;
  };
}
type SearchApiBatchGetTypeRecord = {
  [Table in SearchTable]:
    | SearchApiBatchGetType<Table, 'full'>
    | SearchApiBatchGetType<Table, 'sanitized'>;
};
type SearchApiBatchGetEndpoint = SearchApiBatchGetTypeRecord[keyof SearchApiBatchGetTypeRecord];

//
// SearchGet
//

export interface SearchApiScanType<Table extends SearchTable, Mode extends 'full' | 'sanitized'> {
  req: {
    table: Table;
    mode: Mode;
  };
  res: {
    items: (Mode extends 'full' ? FullItem<Table> : SanitizedItem<Table>)[];
    took: number;
  };
}
type SearchApiScanTypeRecord = {
  [Table in SearchTable]: SearchApiScanType<Table, 'full'> | SearchApiScanType<Table, 'sanitized'>;
};
type SearchApiScanEndpoint = SearchApiScanTypeRecord[keyof SearchApiScanTypeRecord];

//

export interface SearchDumpResult {
  itemsById: Record<string, unknown>;
  indexes: Record<string, {key: unknown; id: unknown}[]>;
}

interface HoobiizExpertTicketStockTicketBase {
  stockId: HoobiizExpertTicketStockId;
  ticketInfoId: HoobiizExpertTicketInfoId;
  productId: HoobiizExpertTicketProductId;
  label: string;
  description?: string;
  minQuantity: number;
  maxQuantity: number;
}

export interface HoobiizExpertTicketFlexibleStock {
  tickets: (HoobiizExpertTicketStockTicketBase & {price: number})[];
}

export interface HoobiizExpertTicketFixedStock {
  tickets: (HoobiizExpertTicketStockTicketBase & {
    prices: {ts: number; price: number}[];
    originalPrice: number;
  })[];
}

export const SearchApi: ApiMetadata<{
  '/search/query': SearchApiQueryEndpoint;
  '/search/get': SearchApiGetEndpoint;
  '/search/batch-get': SearchApiBatchGetEndpoint;
  '/search/scan': SearchApiScanEndpoint;
  //
  '/search/activity-stock': {
    req: {activityId: HoobiizActivityId; startTs: number; endTs: number};
    res: {
      stocks: {stock: SanitizedItem<'HoobiizStock'>; offers: SanitizedItem<'HoobiizOffer'>[]}[];
      expertTicketFlexibleStocks: HoobiizExpertTicketFlexibleStock[];
      expertTicketFixedStocks: HoobiizExpertTicketFixedStock[];
      hasFixedStocks: boolean;
      hasFlexibleStocks: boolean;
      took: number;
    };
  };
  '/search/admin-activity-stock': {
    req: {activityId: HoobiizActivityId; startTs: number; endTs: number};
    res: {
      stocks: {stock: FullItem<'HoobiizStock'>; offers: SanitizedItem<'HoobiizOffer'>[]}[];
      took: number;
    };
  };
  //
  '/notify': {req: {tableName: SearchTable; key: Record<string, unknown>}[]; res: None};
  '/notify-internal': {req: {tableName: SearchTable; key: Record<string, unknown>}[]; res: None};
  '/list': {req: {}; res: {tables: {name: SearchTable; items: number}[]}};
  '/dump': {req: {table: SearchTable}; res: string};
}> = {host: searchHost, ipv6Compatible: true};
