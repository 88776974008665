import {useCallback, useEffect, useState} from 'react';

import {ApiMetadata} from '@shared/api/model';
import {ApiCallReq, ApiCallRes, ApiDef} from '@shared/api/registry';
import {NO_TIMEOUT} from '@shared/constants/uncategorized_constants';

import {apiCall} from '@shared-frontend/api';
import {notifyError} from '@shared-frontend/lib/notification';
import {useMemoCompare} from '@shared-frontend/lib/use_memo_compare';

export function useApiCall<
  Api extends ApiMetadata<unknown>,
  Path extends keyof ApiDef<Api>,
  Req extends ApiCallReq<ApiDef<Api>[Path]>,
>(
  api: Api,
  path: Path,
  req: Req,
  opts?: {errMsg?: string; noTimeout?: boolean}
): {
  data: ApiCallRes<ApiDef<Api>[Path]> | undefined;
  setData: React.Dispatch<React.SetStateAction<ApiCallRes<ApiDef<Api>[Path]> | undefined>>;
  refreshData: () => Promise<void>;
} {
  const [data, setData] = useState<ApiCallRes<ApiDef<Api>[Path]> | undefined>();
  const cachedReq = useMemoCompare(req);
  const cachedOpts = useMemoCompare(opts);

  const refreshData = useCallback(async () => {
    const {errMsg, noTimeout} = cachedOpts ?? {};
    await apiCall(api, path, cachedReq, {timeout: noTimeout ? NO_TIMEOUT : undefined})
      .then(setData)
      .catch(err =>
        errMsg !== undefined ? notifyError(err, {message: errMsg}) : notifyError(err)
      );
  }, [api, path, cachedReq, cachedOpts]);

  useEffect(() => {
    refreshData().catch(() => {});
  }, [refreshData]);

  return {data, setData, refreshData};
}
