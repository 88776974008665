import {Fragment, useCallback} from 'react';
import styled from 'styled-components';

import {HoobiizCartItemItemFull} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizCartItemItem} from '@shared/dynamo_model';
import {multiplyDiscountedCurrencyAmount} from '@shared/lib/hoobiiz/discounted_currency_amount';
import {removeUndefined} from '@shared/lib/type_utils';

import {UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Custom, EmptyFragment} from '@shared-frontend/lib/react';

import {SmallAlertButton} from '@src/components/core/theme';
import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';
import {HoobiizPrices} from '@src/components/ui/hoobiiz_prices';
import {getDiscount} from '@src/lib/discount';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface HoobiizCartItemProps {
  fullCartItem: HoobiizCartItemItemFull;
  onDeleteClick: (item: HoobiizCartItemItem) => Promise<void>;
}

export const HoobiizCartItem: Custom<HoobiizCartItemProps, 'div'> = props => {
  const {fullCartItem, onDeleteClick, ...rest} = props;

  const handleDeleteClick = useCallback(async () => {
    await onDeleteClick(fullCartItem.cartItem);
  }, [fullCartItem, onDeleteClick]);

  let options = EmptyFragment;
  if (fullCartItem.type === 'ticket') {
    options = (
      <Fragment>
        {fullCartItem.cartItem.itemData.options.map(({optionId, quantity}) => {
          const option = fullCartItem.ticketInfo.options.find(o => o.id === optionId);
          if (!option) {
            return <Fragment key={optionId} />;
          }
          const optionTitle = ` ${quantity > 1 ? `x${quantity} ` : ''}${option.label}`;
          const optionPrice = getDiscount(option, removeUndefined([]));
          return (
            <OptionTitle key={optionId}>
              {optionTitle}
              <HoobiizPrices
                prices={multiplyDiscountedCurrencyAmount(quantity, optionPrice.amount)}
                justify="flex-end"
              />
            </OptionTitle>
          );
        })}
      </Fragment>
    );
  }

  const {id, itemData} = fullCartItem.cartItem;
  const ticketTitle = `${itemData.quantity > 1 ? `x${itemData.quantity} ` : ''}${
    fullCartItem.ticketInfo.label
  }`;
  const ticketPrice =
    fullCartItem.type === 'ticket'
      ? getDiscount(fullCartItem.ticketInfo, removeUndefined([fullCartItem.offer]))
      : getDiscount(
          {
            youpiizPrice: fullCartItem.price,
            publicPrice: fullCartItem.originalPrice,
          },
          []
        );
  return (
    <ItemWrapper {...rest}>
      <ActivityTitleLink to={getActivityUrl(fullCartItem.activity)}>
        {fullCartItem.activity.label}
      </ActivityTitleLink>
      <ItemInfo>
        <StyledHoobiizMediaView media={fullCartItem.activity.media[0]} width={128} height={128} />
        <Right>
          <RightTop>
            <ItemTitle>
              <TicketTitle>
                {ticketTitle}
                <HoobiizPrices
                  prices={multiplyDiscountedCurrencyAmount(itemData.quantity, ticketPrice.amount)}
                  justify="flex-end"
                />
              </TicketTitle>
              {options}
            </ItemTitle>
          </RightTop>
          <SmallAlertButton data-id={id} onClickAsync={handleDeleteClick}>
            Supprimer
          </SmallAlertButton>
        </Right>
      </ItemInfo>
    </ItemWrapper>
  );
};

HoobiizCartItem.displayName = 'HoobiizCartItem';

const StyledHoobiizMediaView = styled(HoobiizMediaView)`
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 800px;
  border: solid 1px #bcbcbc;
  padding: 16px 24px 8px 24px;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityTitleLink = styled(UnthemedNavLink)`
  color: #333333;
  font-size: 24px;
  line-height: 150%;
  &:hover {
    text-decoration: underline;
  }
`;

const TicketTitle = styled.div`
  color: #555555;
  font-size: 20px;
  line-height: 150%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

const OptionTitle = styled.div`
  color: #555555;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

const ItemInfo = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 22px;
`;

const Right = styled.div`
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-x: auto;
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
