import {AccountDropdown} from './account_dropdown';
import {CartNavLink} from './cart_nav_link';
import {FC, Fragment} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import {useTheme} from '@shared/frontends/theme_context';

import {NavLink, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {useSession} from '@shared-frontend/lib/session_store';

import {Colors, Sizes} from '@src/components/core/theme_base';
import {SearchBar} from '@src/components/ui/search_bar';
import {ZINDEX} from '@src/lib/zindex';

export const Navigation: FC = () => {
  const session = useSession();
  const location = useLocation();
  const {
    main: {logo},
  } = useTheme();

  if (['/404', '/devenir-partenaire'].includes(location.pathname)) {
    return EmptyFragment;
  }

  return (
    <Wrapper hidden={location.pathname === '/404'}>
      <Container>
        <Brand to="/">{logo}</Brand>
        <StyledSearchBar />
        <Right>
          {session === undefined ? (
            <Fragment>
              <NavLink to="/login" state={{from: location.pathname}} replace>
                Connexion
              </NavLink>
              <NavLink to="/register">Inscription</NavLink>
            </Fragment>
          ) : (
            <Fragment>
              <CartNavLink />
              <AccountDropdown />
            </Fragment>
          )}
        </Right>
      </Container>
    </Wrapper>
  );
};
Navigation.displayName = 'Navigation';

export const NAV_HEIGHT = 91;

const Wrapper = styled.nav`
  width: 100%;
  height: ${NAV_HEIGHT}px;
  z-index: ${ZINDEX.NavBar};
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  background-color: ${Colors.White};
`;

const Container = styled.div`
  width: 100%;
  max-width: ${Sizes.Content};
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  gap: 32px;
`;

const Brand = styled(UnthemedNavLink)`
  flex-shrink: 0;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const StyledSearchBar = styled(SearchBar)`
  flex-grow: 1;
`;
