import {FC, Fragment, JSX, useCallback, useState} from 'react';
import styled from 'styled-components';

import {EmailString} from '@shared/dynamo_model';
import {useTheme} from '@shared/frontends/theme_context';

import {Button, NavLink, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';
import {notifyError} from '@shared-frontend/lib/notification';
import {EmptyFragment} from '@shared-frontend/lib/react';
import {optionalPx} from '@shared-frontend/lib/styled_utils';

interface RegisterFormProps {
  header?: string | JSX.Element;
  initialEmail?: EmailString;
  emailDisabled?: boolean;
  noLegalNotice?: boolean;
  onRegisterSubmit: (email: EmailString, password: string) => Promise<void>;
}

export const RegisterForm: FC<RegisterFormProps> = props => {
  const {header, initialEmail, emailDisabled, noLegalNotice, onRegisterSubmit} = props;
  const {
    main: {textColor, logo},
  } = useTheme();

  const [email, setEmail] = useState<EmailString | undefined>(initialEmail);
  const [password, setPassword] = useState<string | undefined>();

  const handleRegisterSubmit = useCallback(async () => {
    if (email === undefined || password === undefined) {
      notifyError('Merci de renseigner un email et mot de passe');
      return;
    }
    return onRegisterSubmit(email, password);
  }, [email, onRegisterSubmit, password]);

  return (
    <Form>
      <LogoNavLink to="/">{logo}</LogoNavLink>
      <Spacing height={24} />
      <Title>{header ?? 'Inscription'}</Title>
      <Subtitle>Créer votre compte</Subtitle>
      <Separator $color={textColor} $top={22} $bottom={18} />
      <FormInput>
        <Input
          width="100%"
          type="email"
          value={email}
          syncState={setEmail}
          placeholder="jean@exemple.com"
          label="ADRESSE EMAIL"
          autoFocus={!emailDisabled}
          disabled={emailDisabled}
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          type="password"
          value={password}
          syncState={setPassword}
          label="MOT DE PASSE (min. 8 charactères)"
          autoFocus={emailDisabled}
        />
      </FormInput>
      {noLegalNotice ? (
        EmptyFragment
      ) : (
        <Fragment>
          <Spacing height={28} />
          <LegalText>
            En continuant, vous reconnaissez avoir lu les{' '}
            <NavLink to="/terms">mentions légales</NavLink>
          </LegalText>
        </Fragment>
      )}
      <Spacing height={28} />
      <Button expand type="button" submit onClickAsync={handleRegisterSubmit}>
        Créer mon compte
      </Button>
    </Form>
  );
};

RegisterForm.displayName = 'RegisterForm';

const Form = styled.form`
  width: 95vw;
  max-width: 360px;
  height: 100%;
  margin: auto;
  padding: 32px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 1.5;
`;

const LogoNavLink = styled(UnthemedNavLink)`
  height: 64px;
  & > * {
    width: 100%;
    height: 100%;
  }
`;

const Title = styled.h3`
  font-weight: 700;
  font-size: 160%;
`;

const Subtitle = styled.p`
  opacity: 0.6;
  font-weight: 400;
`;

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;

const Separator = styled.div<{$color: string; $top?: number; $bottom?: number}>`
  height: 1px;
  width: 100%;
  background-color: ${p => p.$color}20;
  ${p => optionalPx('margin-top', p.$top)}
  ${p => optionalPx('margin-bottom', p.$bottom)}
`;

const LegalText = styled.div`
  width: 100%;
  text-align: left;
  font-size: 80%;
`;
