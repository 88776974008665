import {FC} from 'react';
import styled from 'styled-components';

import {SanitizedHoobiizOrderItemItem} from '@shared/api/definitions/public_api/hoobiiz_api';
import {HoobiizOrderStatus} from '@shared/dynamo_model';
import {roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {baseDiscountedCurrencyAmount} from '@shared/lib/hoobiiz/discounted_currency_amount';

import {Link, UnthemedNavLink} from '@shared-frontend/components/core/button';
import {SvgIcon} from '@shared-frontend/components/core/svg_icon';
import {EmptyFragment} from '@shared-frontend/lib/react';

import {Colors} from '@src/components/core/theme_base';
import {HoobiizMediaView} from '@src/components/ui/hoobiiz_media_view';
import {HoobiizOrderItemStatus} from '@src/components/ui/hoobiiz_order_item_status';
import {HoobiizPrices} from '@src/components/ui/hoobiiz_prices';
import {getActivityUrl} from '@src/lib/hoobiiz_urls';

interface HoobiizOrderItemProps {
  orderItem: SanitizedHoobiizOrderItemItem;
}

export const HoobiizOrderItem: FC<HoobiizOrderItemProps> = props => {
  const {orderItem} = props;
  const {
    activity,
    quantity,
    total,
    ticketInfo,
    ticketInfoTotal,
    options,
    fees,
    documentUrls,
    validityDateStr,
    status,
  } = orderItem;
  const ticketTitle = `${quantity > 1 ? `x${quantity} ` : ''}${ticketInfo.label}`;

  return (
    <ItemWrapper>
      <StyledHoobiizOrderItemStatus status={status} />
      <div>
        <ActivityTitleLink to={getActivityUrl(activity)}>{activity.label}</ActivityTitleLink>
        {validityDateStr === undefined ? (
          EmptyFragment
        ) : (
          <ValidityDate>{validityDateStr}</ValidityDate>
        )}
      </div>
      <ItemInfo>
        <StyledHoobiizMediaView media={activity.media[0]} width={128} height={128} />
        <Right>
          <RightTop>
            <ItemTitle>
              <TicketTitle>
                <TicketTitleLink to={getActivityUrl(activity)}>{ticketTitle}</TicketTitleLink>
                <HoobiizPrices prices={ticketInfoTotal} justify="flex-end" />
              </TicketTitle>
              {options.map(({option, quantity, total}) => {
                const optionTitle = ` ${quantity > 1 ? `x${quantity} ` : ''}${option.label}`;
                return (
                  <OptionTitle key={option.id}>
                    <div>{optionTitle}</div>
                    <HoobiizPrices prices={total} justify="flex-end" />
                  </OptionTitle>
                );
              })}
            </ItemTitle>
            {roundCents(fees.cents) > 0 ? (
              <FeeTitle>
                <FeeTitleText>Frais de gestion</FeeTitleText>
                <HoobiizPrices prices={baseDiscountedCurrencyAmount(fees)} justify="flex-end" />
              </FeeTitle>
            ) : (
              EmptyFragment
            )}
            {roundCents(fees.cents) > 0 || options.length > 0 ? (
              <TotalTitle>
                <div>Total</div>
                <HoobiizPrices prices={total} justify="flex-end" />
              </TotalTitle>
            ) : (
              EmptyFragment
            )}
          </RightTop>
          {documentUrls.length === 0 && status === HoobiizOrderStatus.Success ? (
            <DocumentLoading>Création de vos billets en cours...</DocumentLoading>
          ) : (
            documentUrls.map(url => (
              <DocumentLink key={url} href={url} target="_blank">
                <SvgIcon name="Download" color={Colors.DarkGold} size={16} /> Télécharger
              </DocumentLink>
            ))
          )}
        </Right>
      </ItemInfo>
    </ItemWrapper>
  );
};

HoobiizOrderItem.displayName = 'HoobiizOrderItem';

const StyledHoobiizMediaView = styled(HoobiizMediaView)`
  flex-shrink: 0;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  max-width: 800px;
  border: solid 1px #bcbcbc;
  padding: 16px 24px 8px 24px;
  border-radius: 8px;
  overflow: hidden;
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityTitleLink = styled(UnthemedNavLink)`
  color: #333333;
  font-size: 24px;
  line-height: 150%;
  &:hover {
    text-decoration: underline;
  }
`;

const ValidityDate = styled.div`
  color: #555555;
  line-height: 150%;
`;

const BaseTitle = styled.div`
  color: #555555;
  line-height: 150%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;

const TicketTitle = styled(BaseTitle)`
  font-size: 20px;
`;

const TicketTitleLink = styled(UnthemedNavLink)`
  color: #000000;
  &:hover {
    text-decoration: underline;
  }
`;

const OptionTitle = styled(BaseTitle)`
  font-size: 18px;
`;

const FeeTitle = styled(BaseTitle)`
  font-size: 15px;
`;
const FeeTitleText = styled.div`
  font-style: italic;
`;

const TotalTitle = styled(BaseTitle)`
  color: #333333;
  font-size: 24px;
`;

const ItemInfo = styled.div`
  display: flex;
  margin-bottom: 16px;
  gap: 22px;
`;

const Right = styled.div`
  width: 1px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-x: auto;
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const DocumentLink = styled(Link)`
  gap: 4px;
`;

const DocumentLoading = styled.div`
  color: #999;
  font-style: italic;
  font-size: 16px;
  font-weight: 500;
`;

const StyledHoobiizOrderItemStatus = styled(HoobiizOrderItemStatus)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0;
  border-bottom-left-radius: 8px;
`;
