import {useEffect, useState} from 'react';

import {deepEqual} from '@shared/lib/object_utils';

export function useMemoCompare<T>(val: T): T {
  const [cachedVal, setCachedVal] = useState(val);

  useEffect(() => {
    if (cachedVal !== val && !deepEqual(cachedVal, val)) {
      setCachedVal(val);
    }
  }, [cachedVal, val]);

  return cachedVal;
}
