import {FC, useCallback} from 'react';
import {styled} from 'styled-components';

import {
  AddressLookupResult,
  HoobiizOpeningHours,
  HoobiizVendorLocation,
} from '@shared/dynamo_model';
import {values} from '@shared/lib/map_utils';

import {Checkbox} from '@shared-frontend/components/core/checkbox';
import {Input} from '@shared-frontend/components/core/input_v2';

import {AddressInput} from '@src/components/admin/form/address_input';
import {FormBlockAuto, FormFlex, FormLabel} from '@src/components/admin/form/form_fragments';
import {OpeningHoursForm} from '@src/components/admin/form/opening_hours_form';
import {adminInputTheme} from '@src/components/core/theme';
import {emptyHoobiizOpeningHours} from '@src/lib/hoobiiz_week_periods';

interface VendorLocationFormProps {
  location?: Partial<HoobiizVendorLocation>;
  onChange: (data: Partial<HoobiizVendorLocation>) => void;
}

export const VendorLocationForm: FC<VendorLocationFormProps> = props => {
  const {location, onChange} = props;

  const setName = useCallback(
    (name: string) => onChange({...location, name}),
    [location, onChange]
  );
  const setHideName = useCallback(
    (hideName: boolean) => onChange({...location, hideName}),
    [location, onChange]
  );
  const setPhone = useCallback(
    (phone: string) => onChange({...location, phone}),
    [location, onChange]
  );
  const setHidePhone = useCallback(
    (hidePhone: boolean) => onChange({...location, hidePhone}),
    [location, onChange]
  );
  const setAddress = useCallback(
    (address: AddressLookupResult | undefined) => onChange({...location, address}),
    [location, onChange]
  );
  const setHideAddress = useCallback(
    (hideAddress: boolean) => onChange({...location, hideAddress}),
    [location, onChange]
  );
  const setOpeningHours = useCallback(
    (openingHours: HoobiizOpeningHours) => {
      for (const day of values(openingHours.weekdays)) {
        day.sort((p1, p2) => p1.startHour - p2.startHour);
      }
      onChange({...location, openingHours});
    },
    [location, onChange]
  );
  const setHideOpeningHours = useCallback(
    (hideOpeningHours: boolean) => onChange({...location, hideOpeningHours}),
    [location, onChange]
  );

  return (
    <FormFlex>
      <FormBlockAuto $gap={4}>
        <Input
          width="100%"
          value={location?.name ?? ''}
          syncState={setName}
          placeholder={location?.name}
          label="NOM"
          overrides={adminInputTheme}
        />
        <Checkbox checked={location?.hideName} syncState={setHideName}>
          Cacher le nom
        </Checkbox>
      </FormBlockAuto>
      <FormBlockAuto $gap={4}>
        <Input
          width="100%"
          value={location?.phone ?? ''}
          syncState={setPhone}
          placeholder={location?.phone}
          label="TÉLÉPHONE"
          overrides={adminInputTheme}
        />
        <Checkbox checked={location?.hidePhone ?? true} syncState={setHidePhone}>
          Cacher le téléphone
        </Checkbox>
      </FormBlockAuto>
      <AddressBlock>
        <AddressInput address={location?.address} syncState={setAddress} />
        <Checkbox checked={location?.hideAddress} syncState={setHideAddress}>
          Cacher l'adresse
        </Checkbox>
      </AddressBlock>
      <OpeningHoursWrapper>
        <FormLabel>HORAIRES D'OUVERTURE</FormLabel>
        <OpeningHoursForm
          openingHours={location?.openingHours ?? emptyHoobiizOpeningHours()}
          onChange={setOpeningHours}
        ></OpeningHoursForm>
        <Checkbox checked={location?.hideOpeningHours} syncState={setHideOpeningHours}>
          Cacher les horaires d'ouverture
        </Checkbox>
      </OpeningHoursWrapper>
    </FormFlex>
  );
};
VendorLocationForm.displayName = 'VendorLocationForm';

const AddressBlock = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  flex-grow: 1;
  gap: 4px;
`;

const OpeningHoursWrapper = styled(FormBlockAuto)`
  width: 100%;
`;
