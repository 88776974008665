import {
  HoobiizCartItemItemFull,
  SanitizedHoobiizOrderItemItem,
} from '@shared/api/definitions/public_api/hoobiiz_api';
import {CurrencyAmount} from '@shared/dynamo_model';
import {addCurrencyAmount, cents} from '@shared/lib/hoobiiz/currency_amount';
import {
  addDiscountedCurrencyAmount,
  baseDiscountedCurrencyAmount,
  computeCartItemPrice,
  DiscountedCurrencyAmount,
} from '@shared/lib/hoobiiz/discounted_currency_amount';

export function computeCartTotal(cartItems: HoobiizCartItemItemFull[]): {
  total: DiscountedCurrencyAmount;
  fees: CurrencyAmount;
} {
  let total = baseDiscountedCurrencyAmount(cents(0));
  let fees = cents(0);
  for (const item of cartItems) {
    const itemPrice = computeCartItemPrice(item);
    total = addDiscountedCurrencyAmount(total, itemPrice.total);
    fees = addCurrencyAmount(fees, itemPrice.fees);
  }
  return {total, fees};
}

export function computeOrdersTotal(orders: SanitizedHoobiizOrderItemItem[]): {
  total: DiscountedCurrencyAmount;
  fees: CurrencyAmount;
} {
  let total = baseDiscountedCurrencyAmount(cents(0));
  let fees = cents(0);
  for (const item of orders) {
    total = addDiscountedCurrencyAmount(total, item.total);
    fees = addCurrencyAmount(fees, item.fees);
  }
  return {total, fees};
}
