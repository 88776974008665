import {HoobiizDiscount, HoobiizDiscountPercent, HoobiizDiscountType} from '@shared/dynamo_model';
import {cents, currencyAmountToString, roundCents} from '@shared/lib/hoobiiz/currency_amount';
import {
  baseDiscountedCurrencyAmount,
  DiscountedCurrencyAmount,
  discountedCurrencyAmountToPercent,
  flattenDiscountedCurrencyAmount,
} from '@shared/lib/hoobiiz/discounted_currency_amount';
import {applyDiscount, isPositiveDiscount} from '@shared/lib/hoobiiz/offer';
import {neverHappens} from '@shared/lib/type_utils';
import {SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';

import {Colors} from '@src/components/core/theme_base';

export const noDiscount: HoobiizDiscountPercent = {type: HoobiizDiscountType.Percent, percent: 0};

export function discountToLongString(discount: HoobiizDiscount): string {
  if (discount.type === HoobiizDiscountType.Amount) {
    return `${currencyAmountToString(discount.amount)} de réduction`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (discount.type === HoobiizDiscountType.Percent) {
    return `${Math.round(discount.percent).toLocaleString()}% de réduction`;
  }
  neverHappens(discount, `Invalid discount type "${(discount as HoobiizDiscount).type}"`);
}

export function discountToString(discount: HoobiizDiscount): string {
  if (discount.type === HoobiizDiscountType.Amount) {
    return `-${currencyAmountToString(discount.amount)}`;
  }
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  else if (discount.type === HoobiizDiscountType.Percent) {
    return `-${Math.round(discount.percent).toLocaleString()}%`;
  }
  neverHappens(discount, `Invalid discount type "${(discount as HoobiizDiscount).type}"`);
}

export function getDiscount(
  ticketInfo: Pick<SanitizedTicketInfo, 'publicPrice' | 'youpiizPrice'> | undefined,
  offers: SanitizedItem<'HoobiizOffer'>[]
): {
  amount: DiscountedCurrencyAmount;
  totalDiscount: HoobiizDiscountPercent;
  totalDiscountColor: string;
  bestOffer?: SanitizedItem<'HoobiizOffer'>;
} {
  if (!ticketInfo) {
    return {
      amount: baseDiscountedCurrencyAmount(cents(0)),
      totalDiscount: {type: HoobiizDiscountType.Percent, percent: 0},
      totalDiscountColor: Colors.Grey,
    };
  }

  const price1 = ticketInfo.publicPrice;
  const price2 = ticketInfo.youpiizPrice;
  let price3 = ticketInfo.youpiizPrice;

  let bestOffer: SanitizedItem<'HoobiizOffer'> | undefined;
  for (const offer of offers) {
    if (isPositiveDiscount(offer.discount)) {
      const newPrice = applyDiscount(offer.discount, price2);
      if (newPrice.cents < roundCents(price3.cents)) {
        bestOffer = offer;
        price3 = newPrice;
      }
    }
  }

  const amount = {price1, price2, price3};
  const prices = flattenDiscountedCurrencyAmount(amount);
  const discountCount = prices.length - 1;
  const totalDiscountColor =
    discountCount === 0 ? Colors.Grey : discountCount === 1 ? Colors.Green : Colors.Red;

  return {
    amount: {price1, price2, price3},
    totalDiscount: discountedCurrencyAmountToPercent(amount),
    totalDiscountColor,
    bestOffer,
  };
}

/* eslint-disable @typescript-eslint/no-magic-numbers */
export function getDiscountColor(discount?: HoobiizDiscountPercent): string {
  if (!discount || discount.percent <= 0) {
    return Colors.Green;
  } else if (discount.percent < 20) {
    return '#b0b900';
  } else if (discount.percent < 40) {
    return '#b97500';
  } else if (discount.percent < 60) {
    return '#b94700';
  }
  return '#b90000';
}
/* eslint-enable @typescript-eslint/no-magic-numbers */
