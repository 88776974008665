import {endOfUtcDay, franceToUtc, startOfUtcDay, utcToFrance} from '@shared/lib/date_utils';
import {findBestOffer} from '@shared/lib/hoobiiz/offer';
import {NonEmptyArray, nonEmptyArray, removeUndefined} from '@shared/lib/type_utils';
import {FullItem, SanitizedItem, SanitizedTicketInfo} from '@shared/model/search_tables';

// Take an array of HoobiizStock and return all the combination of
// consecutive items (eg: stock 12:00-13:00 and stock 13:00-14:00)
// of size `span`
function groupConsecutiveStocks(
  stocks: {stock: SanitizedItem<'HoobiizStock'>; bestOffer?: SanitizedItem<'HoobiizOffer'>}[],
  span: number
): {
  stocks: NonEmptyArray<SanitizedItem<'HoobiizStock'>>;
  bestOffer?: SanitizedItem<'HoobiizOffer'>;
}[] {
  // Sort stocks by start time
  const sorted = [...stocks].sort(
    (s1, s2) => s1.stock.reservation.period.startTs - s2.stock.reservation.period.startTs
  );

  const res: {
    stocks: NonEmptyArray<SanitizedItem<'HoobiizStock'>>;
    bestOffer?: SanitizedItem<'HoobiizOffer'>;
  }[] = [];
  // Try to create a chain of consecutive stocks starting from each stocks
  for (const [stockIndex, {stock, bestOffer}] of sorted.entries()) {
    const chain: NonEmptyArray<SanitizedItem<'HoobiizStock'>> = [stock];
    let chainOffer = bestOffer;
    let lastStock = stock;
    for (let i = 1; i < span; i++) {
      const nextStock = sorted[stockIndex + i];
      // Check if the lastStock of the chain and the next stock are consecutive
      if (nextStock?.stock.reservation.period.startTs === lastStock.reservation.period.endTs) {
        lastStock = nextStock.stock;
        if (nextStock.bestOffer === undefined) {
          chainOffer = undefined;
        }
        chain.push(nextStock.stock);
      } else {
        break;
      }
    }
    if (chain.length === span) {
      res.push({stocks: chain, bestOffer: chainOffer});
    }
  }

  return res;
}

export function groupStockByTicketInfo(
  stockAndOffers: {
    stock: SanitizedItem<'HoobiizStock'>;
    offers: SanitizedItem<'HoobiizOffer'>[];
  }[]
): {
  stockGroups: NonEmptyArray<{
    stocks: NonEmptyArray<SanitizedItem<'HoobiizStock'>>;
    bestOffer?: SanitizedItem<'HoobiizOffer'>;
  }>;
  bestOffer?: SanitizedItem<'HoobiizOffer'>;
  ticketInfo: SanitizedTicketInfo;
}[] {
  // Find all the unique ticket info and the best offers
  const stockByTicketInfo = new Map<
    string,
    {
      stocks: NonEmptyArray<{
        stock: SanitizedItem<'HoobiizStock'>;
        bestOffer?: SanitizedItem<'HoobiizOffer'>;
      }>;
      ticketInfo: SanitizedTicketInfo;
    }
  >();
  for (const {stock, offers} of stockAndOffers) {
    for (const ticketInfo of stock.availableTickets) {
      const hash = [
        ticketInfo.label,
        ticketInfo.publicPrice.cents,
        ticketInfo.youpiizPrice.cents,
      ].join(':');
      const bestOffer = findBestOffer(ticketInfo, offers);
      let ticketInfoStocks = stockByTicketInfo.get(hash);
      if (!ticketInfoStocks) {
        ticketInfoStocks = {
          stocks: [{stock, bestOffer}],
          ticketInfo,
        };
        stockByTicketInfo.set(hash, ticketInfoStocks);
      } else {
        ticketInfoStocks.stocks.push({stock, bestOffer});
      }
    }
  }

  const res = removeUndefined(
    [...stockByTicketInfo.values()].map(ticketInfoStock => {
      const stockGroups = nonEmptyArray(
        groupConsecutiveStocks(ticketInfoStock.stocks, ticketInfoStock.ticketInfo.span ?? 1)
      );
      if (!stockGroups) {
        return undefined;
      }
      const bestOffer = findBestOffer(
        ticketInfoStock.ticketInfo,
        removeUndefined(stockGroups.map(s => s.bestOffer))
      );
      return {
        stockGroups,
        ticketInfo: ticketInfoStock.ticketInfo,
        bestOffer,
      };
    })
  );

  // Sort ticket info
  // by duration (short to long) -> Show 30 min before 1h, price is more attractive
  // then by price (high to low) -> Usually the lower price is the price for children
  return res.sort((a, b) => {
    const stockA = a.stockGroups[0].stocks[0];
    const stockB = b.stockGroups[0].stocks[0];
    const durationA = stockA.reservation.period.endTs - stockA.reservation.period.startTs;
    const durationB = stockB.reservation.period.endTs - stockB.reservation.period.startTs;
    const spanDiff = durationA * (a.ticketInfo.span ?? 1) - durationB * (b.ticketInfo.span ?? 1);
    if (spanDiff !== 0) {
      return spanDiff;
    }
    return b.ticketInfo.youpiizPrice.cents - a.ticketInfo.youpiizPrice.cents;
  });
}

export function getStocksAndOffersAtDate<
  T extends SanitizedItem<'HoobiizStock'> | FullItem<'HoobiizStock'>,
>(
  stocks: {stock: T; offers: SanitizedItem<'HoobiizOffer'>[]}[],
  franceDate: Date
): {
  stock: T;
  offers: SanitizedItem<'HoobiizOffer'>[];
}[] {
  const dateAsUTC = utcToFrance(franceDate);
  const startOfDateAsUtc = startOfUtcDay(dateAsUTC);
  const endOfDateAsUtc = endOfUtcDay(dateAsUTC);
  const startOfDate = franceToUtc(startOfDateAsUtc);
  const endOfDate = franceToUtc(endOfDateAsUtc);
  return stocks.filter(
    stock =>
      !(stock.stock.reservation.period.startTs > endOfDate.getTime()) &&
      !(stock.stock.reservation.period.endTs < startOfDate.getTime())
  );
}
