import {utcToFrance} from '@shared/lib/date_utils';
import {ExpertTicketProductPriceAndDates} from '@shared/model/hoobiiz/expert_ticket_api/expert_ticket_product';

export interface ExpertTicketCancellationPolicy {
  /** Indicates if the customer can cancel for free at some point in time. */
  IsRefundable: boolean;
  /** Rules that apply when canceling. */
  Rules: {
    /** Indicates the number of hours in advance regarding the date of access from which the cancellation fee set in Percentage will be applied. */
    HoursInAdvanceOfAccess: number;
    /** Percentage of the total product amount that won't be refunded in case of cancellation. */
    Percentage: number;
  };
}

export interface ExpertTicketTaxBreakdown {
  TaxPercentage: number;
  PriceWithoutTaxes: number;
  PriceWithTaxes: number;
}

export function parseExpertTicketPricesAndDates(
  pricesAndDates: ExpertTicketProductPriceAndDates[]
): {price: number; date: Date}[] {
  return pricesAndDates.flatMap(priceAndDates =>
    priceAndDates.Dates.split(',').map(dateStr => ({
      price: priceAndDates.Price,
      date: utcToFrance(new Date(dateStr)),
    }))
  );
}
