import {CSSProperties} from 'react';
import styled from 'styled-components';

import {optionalPx} from '@shared-frontend/lib/styled_utils';

import {Colors} from '@src/components/core/theme_base';

export const FormWrapperOld = styled.div``;
export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const FormTitleOld = styled.div`
  font-size: 24px;
  margin-bottom: 24px;
`;
export const FormTitle = styled.div`
  font-size: 24px;
`;

export const FormBlock = styled.div<{$minWidth?: number}>`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 1px;
  min-width: ${p => p.$minWidth ?? '300'}px;
`;

export const FormBlockAuto = styled.div<{$gap?: number}>`
  display: flex;
  flex-direction: column;
  ${p => optionalPx('gap', p.$gap)}
`;

export const FormBlockFix = styled.div<{$width: number}>`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: ${p => p.$width}px;
`;

export const FormBlockFull = styled.div<{$gap?: number}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${p => (p.$gap === undefined ? false : `gap: ${p.$gap}px;`)}
`;

export const FormFlex = styled.div<{$align?: CSSProperties['alignItems']}>`
  display: flex;
  justify-content: flex-start;
  ${p => (p.$align === undefined ? false : `align-items: ${p.$align};`)}
  flex-wrap: wrap;
  gap: 16px;
`;

const formGridDefaultWidth = 250;
export const FormGridWidth = styled.div<{$width?: number}>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(${p => p.$width ?? formGridDefaultWidth}px, 1fr));
  grid-gap: 16px;
`;

export const FormLabel = styled.div<{$noMargin?: boolean}>`
  font-size: 14.4px;
  font-weight: bold;
  color: ${Colors.Grey};
  margin-top: 8px;
  margin-left: ${p => (p.$noMargin ? 0 : '16px')};
  margin-bottom: 10px;
`;
