import {FC} from 'react';
import styled from 'styled-components';

import {SanitizedItem} from '@shared/model/search_tables';

import {DummyCard, HoobiizActivityCard} from '@src/components/ui/hoobiiz_activity_card';

interface HoobiizActivityGridProps {
  activities?: SanitizedItem<'HoobiizActivity'>[];
}

export const HoobiizActivityGrid: FC<HoobiizActivityGridProps> = props => {
  const {activities} = props;
  return (
    <Grid>
      {activities ? (
        activities.map(activity => (
          <HoobiizActivityCard key={activity.id} activity={activity} clickable />
        ))
      ) : (
        <DummyCard />
      )}
    </Grid>
  );
};

HoobiizActivityGrid.displayName = 'HoobiizActivityGrid';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  justify-items: center;
`;
