import {
  HoobiizActivityId,
  HoobiizCat1Id,
  HoobiizCat2Id,
  HoobiizCat3Id,
  HoobiizVendorId,
} from '@shared/dynamo_model';

export function toSlugName(str: string): string {
  return str
    .replace(/[/\\?=#]/u, ' ')
    .split(' ')
    .filter(s => s.length > 0)
    .join('-');
}

export function getCat1Url<T extends {name: string; id: HoobiizCat1Id}>(cat1: T): string {
  return `/c1/${toSlugName(cat1.name)}/${cat1.id}`;
}

export function getCat2Url<T extends {name: string; id: HoobiizCat2Id}>(cat2: T): string {
  return `/c2/${toSlugName(cat2.name)}/${cat2.id}`;
}

export function getCat3Url<T extends {name: string; id: HoobiizCat3Id}>(cat3: T): string {
  return `/c3/${toSlugName(cat3.name)}/${cat3.id}`;
}

export function getActivityUrl<T extends {slugName: string; id: HoobiizActivityId}>(
  activity: T
): string {
  return `/a/${activity.slugName}/${activity.id}`;
}

export function getVendorUrl<T extends {slugName: string; id: HoobiizVendorId}>(vendor: T): string {
  return `/v/${vendor.slugName}/${vendor.id}`;
}
