import {genericApiCall, HttpPoster, HttpPosterRes} from '@shared/api/consumer';
import {FrontendSharedApi} from '@shared/api/definitions/frontend_shared_api';
import {ApiCallOptions, ApiMetadata, UnauthenticatedCallback} from '@shared/api/model';
import {ApiCallReq, ApiCallRes, ApiDef} from '@shared/api/registry';
import {safeParse} from '@shared/api/safe_body';
import {
  API_REQUEST_TIMEOUT,
  CAPTCHA_HEADER_NAME,
  NEXT_CAPTCHA_HEADER_NAME,
} from '@shared/constants/uncategorized_constants';
import {FRONTEND_API_SUBDOMAIN, trimWwwSubdomain} from '@shared/frontends/frontend_constant';
import {asMap, asString} from '@shared/lib/type_utils';

import {getWindowUnsafe} from '@shared-frontend/window';

const httpPostForBrowser: HttpPoster = (
  uri,
  method,
  body,
  headers,
  overrides,
  onUnauthenticated
) => {
  const window = getWindowUnsafe();
  const abortController = new AbortController();
  const abortSignal = abortController.signal;
  const timeoutId = setTimeout(
    () => {
      // eslint-disable-next-line no-console
      console.warn('REQUEST_TIMEOUT', {
        options: JSON.stringify({uri, method: 'POST', headers, body}),
      });
      if (!abortSignal.aborted) {
        abortController.abort();
      }
    },
    overrides.timeout ?? API_REQUEST_TIMEOUT * 1000
  );

  let promise = new Promise<HttpPosterRes>((resolve, reject) => {
    const allHeaders: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...headers,
    };
    if (window.captcha !== undefined) {
      allHeaders[CAPTCHA_HEADER_NAME] = window.captcha;
    }
    fetch(uri, {
      method,
      headers: allHeaders,
      signal: abortSignal,
      credentials: 'include',
      body,
    })
      .then(res => {
        clearTimeout(timeoutId);
        const nextCaptcha = asString(res.headers.get(NEXT_CAPTCHA_HEADER_NAME));
        if (nextCaptcha !== undefined) {
          window.captcha = nextCaptcha;
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (res.status === 401) {
          onUnauthenticated?.();
          throw new Error('Unauthenticated');
        }
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (res.status === 429) {
          setTimeout(() => {
            promise = httpPostForBrowser(
              uri,
              method,
              body,
              headers,
              overrides,
              onUnauthenticated
            ).promise;
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          }, 2000);
          return;
        }
        res
          .text()
          .then(raw => {
            const rawRes = safeParse(raw);
            const jsonMap = asMap(rawRes);
            if (jsonMap === undefined) {
              resolve({status: res.status, err: undefined, rawRes});
              return;
            }
            resolve({status: res.status, res: jsonMap.res, err: jsonMap.err, rawRes});
          })
          .catch(() => {
            resolve({status: res.status, err: undefined, rawRes: undefined});
          });
      })
      .catch(reject);
  });

  return {promise};
};

export async function apiCall<
  Api extends ApiMetadata<unknown>,
  Path extends keyof ApiDef<Api>,
  Req extends ApiCallReq<ApiDef<Api>[Path]>,
>(
  api: Api,
  path: Path,
  req: Req,
  options?: ApiCallOptions,
  onUnauthenticated?: UnauthenticatedCallback
): Promise<ApiCallRes<ApiDef<Api>[Path]>> {
  return genericApiCall(
    api,
    path,
    req,
    httpPostForBrowser,
    api.isFrontend
      ? {
          ...options,
          hostOverride: `//${FRONTEND_API_SUBDOMAIN}.${trimWwwSubdomain(document.location.host)}`,
        }
      : undefined,
    onUnauthenticated
  );
}

export async function sharedApiCall<
  Api extends ApiMetadata<FrontendSharedApi>,
  Path extends keyof FrontendSharedApi,
  Req extends ApiCallReq<FrontendSharedApi[Path]>,
>(
  api: Api,
  path: Path,
  req: Req,
  options?: ApiCallOptions,
  onUnauthenticated?: UnauthenticatedCallback
): Promise<ApiCallRes<FrontendSharedApi[Path]>> {
  return genericApiCall(
    api,
    path,
    req,
    httpPostForBrowser,
    {
      ...options,
      hostOverride: `//${FRONTEND_API_SUBDOMAIN}.${trimWwwSubdomain(document.location.host)}`,
    },
    onUnauthenticated
  ) as Promise<ApiCallRes<FrontendSharedApi[Path]>>;
}
