import {AdminAccountingPage} from './admin/accounting/admin_accounting_page';
import {FC} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';

import {FrontendName} from '@shared/frontends/frontend_constant';

import {FrontendUserDataHoobiizForm} from '@shared-frontend/components/auth/frontend_user_data_hoobiiz_form';
import {useRoute} from '@shared-frontend/components/core/use_route';
import {useSharedRoutes} from '@shared-frontend/components/shared_routes';
import {useComponentClass} from '@shared-frontend/lib/react';

import {ActivityCreatePage} from '@src/components/admin/activity/activity_create_page';
import {ActivityDashboard} from '@src/components/admin/activity/activity_dashboard';
import {ActivityPage} from '@src/components/admin/activity/activity_page';
import {HoobiizActivityOfferCreatePage} from '@src/components/admin/activity_offer/hoobiiz_activity_offer_create_page';
import {ActivityExpertTicketStockCreatePage} from '@src/components/admin/activity_stock/activity_expert_ticket_stock_create_page';
import {ActivityStockCreatePage} from '@src/components/admin/activity_stock/activity_stock_create_page';
import {AdminDashboard} from '@src/components/admin/admin_dashboard';
import {CategoryDashboard} from '@src/components/admin/category/category_dashboard';
import {Category1CreatePage} from '@src/components/admin/category/category1_create_page';
import {Category1UpdatePage} from '@src/components/admin/category/category1_update_page';
import {Category2CreatePage} from '@src/components/admin/category/category2_create_page';
import {Category2UpdatePage} from '@src/components/admin/category/category2_update_page';
import {Category3CreatePage} from '@src/components/admin/category/category3_create_page';
import {Category3UpdatePage} from '@src/components/admin/category/category3_update_page';
import {ExpertTicketPage} from '@src/components/admin/expert_ticket/expert_ticket_page';
import {AdminExplorerPage} from '@src/components/admin/explorer/admin_explorer_page';
import {AdminOrdersPage} from '@src/components/admin/order/admin_orders_page';
import {VendorCreatePage} from '@src/components/admin/vendor/vendor_create_page';
import {VendorDashboard} from '@src/components/admin/vendor/vendor_dashboard';
import {VendorUpdatePage} from '@src/components/admin/vendor/vendor_update_page';
import {AppWrapper} from '@src/components/core/app_wrapper';
import {CleanAppWrapper} from '@src/components/core/clean_app_wrapper';
import {AccountPage} from '@src/components/pages/account_page';
import {AdminOrderPage} from '@src/components/pages/admin_order_page';
import {CartPage} from '@src/components/pages/cart_page';
import {HomePage} from '@src/components/pages/home_page';
import {HoobiizActivityPage} from '@src/components/pages/hoobiiz_activity_page';
import {Cat1Page} from '@src/components/pages/hoobiiz_cat1_page';
import {Cat2Page} from '@src/components/pages/hoobiiz_cat2_page';
import {Cat3Page} from '@src/components/pages/hoobiiz_cat3_page';
import {HoobiizVendorPage} from '@src/components/pages/hoobiiz_vendor_page';
import {NotFoundPage} from '@src/components/pages/not_found_page';
import {OrderPage} from '@src/components/pages/order_page';
import {OrdersPage} from '@src/components/pages/orders_page';
import {PartnerPage} from '@src/components/pages/partner_page';
import {TermsPage} from '@src/components/pages/terms_page';

export const App: FC = () => {
  const seo = {
    title: 'Youpiiz',
    description: 'Youpiiz',
  };
  const adminSeo = {
    title: 'Admin',
    description: 'Admin',
  };

  return (
    <Routes>
      {useSharedRoutes<FrontendName.Hoobiiz>({
        seo: adminSeo,
        appWrapper: AppWrapper,
        // ---------------
        // START ADMIN ROUTES
        // ---------------
        adminRoutes: [
          {path: '/', page: AdminDashboard},
          {path: '/orders', navItem: 'Commandes', page: AdminOrdersPage},
          {path: '/accounting', page: AdminAccountingPage},
          {path: '/vendor', navItem: 'Partenaires', page: VendorDashboard},
          {path: '/vendor/create', page: VendorCreatePage},
          {path: '/vendor/:id', page: VendorUpdatePage},
          {path: '/cat1', navItem: 'Catégories', page: CategoryDashboard},
          {path: '/cat1/create', page: Category1CreatePage},
          {path: '/cat1/:cat1Id', page: Category1UpdatePage},
          {path: '/cat1/:cat1Id/cat2/create', page: Category2CreatePage},
          {path: '/cat1/:cat1Id/cat2/:cat2Id', page: Category2UpdatePage},
          {path: '/cat1/:cat1Id/cat2/:cat2Id/cat3/create', page: Category3CreatePage},
          {path: '/cat1/:cat1Id/cat2/:cat2Id/cat3/:cat3Id', page: Category3UpdatePage},
          {path: '/activity', navItem: 'Activités', page: ActivityDashboard},
          {path: '/activity/create', page: ActivityCreatePage},
          {path: '/activity/:id', page: ActivityPage},
          {path: '/activity/:id/stock/create', page: ActivityStockCreatePage},
          {
            path: '/activity/:id/expert-ticket-stock/create',
            page: ActivityExpertTicketStockCreatePage,
          },
          {path: '/activity/:id/offer/create', page: HoobiizActivityOfferCreatePage},
          {path: '/expert-ticket', navItem: 'Expert Ticket', page: ExpertTicketPage},
          {path: '/explorer', navItem: 'Explorer', page: AdminExplorerPage},
        ],
        userDataForm: FrontendUserDataHoobiizForm,
        // ---------------
        // END ADMIN ROUTES
        // ---------------
      })}
      {/* PUBLIC PAGES */}
      <Route path="/" element={useRoute([AppWrapper, HomePage], seo)} />
      <Route path="/c1/:slug/:cat1Id" element={useRoute([AppWrapper, Cat1Page], seo)}></Route>
      <Route path="/c2/:slug/:cat2Id" element={useRoute([AppWrapper, Cat2Page], seo)}></Route>
      <Route path="/c3/:slug/:cat3Id" element={useRoute([AppWrapper, Cat3Page], seo)}></Route>
      <Route path="/a/:slug/:id" element={useRoute([AppWrapper, HoobiizActivityPage], seo)} />
      <Route path="/v/:slug/:id" element={useRoute([AppWrapper, HoobiizVendorPage], seo)} />
      <Route path="/terms" element={useRoute([AppWrapper, TermsPage], seo)} />
      <Route path="/devenir-partenaire" element={useRoute([AppWrapper, PartnerPage], seo)} />
      <Route
        path="/admin-order/:token"
        element={useRoute([CleanAppWrapper, AdminOrderPage], seo)}
      />
      {/* CONNECTED PAGES */}
      <Route
        path="/cart"
        element={useRoute([AppWrapper, CartPage], seo, {sessionRequired: true})}
      />
      <Route
        path="/orders/:id"
        element={useRoute([AppWrapper, OrderPage], seo, {sessionRequired: true})}
      />
      <Route
        path="/orders"
        element={useRoute([AppWrapper, OrdersPage], seo, {sessionRequired: true})}
      />
      <Route
        path="/account"
        element={useRoute([AppWrapper, AccountPage], seo, {sessionRequired: true})}
      />
      {/* NOT FOUND */}
      <Route
        path="/404"
        element={useRoute(
          useComponentClass(
            <AppWrapper center ignoreBars>
              <NotFoundPage />
            </AppWrapper>
          ),
          {
            title: 'Page introuvable',
            description: 'Cette page n&#x2019;existe plus',
          }
        )}
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
};
App.displayName = 'App';
