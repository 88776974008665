import {FC, Fragment, useEffect, useState} from 'react';
import {styled} from 'styled-components';

import {FrontendUserDataContentType} from '@shared/dynamo_model';

import {FrontendUserDataFormProps} from '@shared-frontend/components/auth/frontend_user_data_form';
import {Input} from '@shared-frontend/components/core/input_v2';
import {Spacing} from '@shared-frontend/components/core/spacing';

export const FrontendUserDataHoobiizForm: FC<
  FrontendUserDataFormProps<FrontendUserDataContentType.Hoobiiz>
> = ({onChange}) => {
  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();

  useEffect(() => {
    if (
      firstName === undefined ||
      firstName.length === 0 ||
      lastName === undefined ||
      lastName.length === 0
    ) {
      onChange(undefined);
      return;
    }
    onChange({type: FrontendUserDataContentType.Hoobiiz, firstName, lastName});
  }, [firstName, lastName, onChange]);

  return (
    <Fragment>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          value={firstName}
          syncState={setFirstName}
          placeholder="Jean"
          label="PRÉNOM"
        />
      </FormInput>
      <Spacing height={12} />
      <FormInput>
        <Input
          width="100%"
          value={lastName}
          syncState={setLastName}
          placeholder="DUPONT"
          label="NOM"
        />
      </FormInput>
    </Fragment>
  );
};
FrontendUserDataHoobiizForm.displayName = 'FrontendUserDataHoobiizForm';

const FormInput = styled.div`
  width: 100%;
  text-align: left;
`;
